module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-global-styles/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/GlobalStyleComponent","props":{"theme":"src/styles/theme","other":{"light":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"pure":true,"minify":true,"critical":true,"ssr":false,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AssoConnect","short_name":"AssoConnect","start_url":"/","background_color":"#316BF2","theme_color":"#316BF2","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aa884c62c12224ee336e6f9506d1b911"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
