import { IMenuItem } from 'src/components/Menu/type'

export const ressources: IMenuItem = {
  title: 'Ressources',
  firstLevelItems: [
    {
      icon: 'common/icon/multicolor/menu/Ressources-temoignages_icone-menu',
      title: 'Témoignages',
      href: '/blog/tag/t%C3%A9moignages/',
    },
    {
      icon: 'common/icon/multicolor/menu/Ressources-guides_icone-menu',
      title: 'Guides gratuits',
      href: '/ressources/guides-associations/',
    },
    {
      icon: 'common/icon/multicolor/menu/Ressources-newsletter_icone-menu',
      title: 'Newsletter',
      href: '/ressources/newsletter/',
    },
    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Ressources-articles_icone-menu',
      title: 'Articles et conseils',
      href: '/blog/',
    },
    {
      icon: 'common/icon/multicolor/menu/Ressources-webinaires_icone-menu',
      title: 'Webinaires',
      href: '/ressources/formations-en-ligne/',
    },
    {
      icon: 'common/icon/multicolor/menu/Ressources-formations_icone-menu',
      title: 'Formations',
      href: '/services/',
    },
  ],
}
