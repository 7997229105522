export interface IMenu {
  menuItems: IMenuItem[]
  cta?: EHeaderCta
  homePath?: string
  headerBackgroundColor?: string
}

export enum EMenuType {
  SHOWCASE = 'SHOWCASE',
}

export enum ELegacyMenuType {
  SERENITY = 'SERENITY',
  PAYMENT = 'PAYMENT',
  NETWORKS = 'NETWORKS',
  ACCOUNTING = 'ACCOUNTING',
  BABYASSO = 'BABYASSO',
  BLOG = 'BLOG',
}

export enum EHeaderCta {
  APP_SUSCRIPTION = 'APP_SUSCRIPTION',
  CONTACT = 'CONTACT',
  PRO_ACCOUNT = 'PRO_ACCOUNT',
  BABY_ASSO = 'BABY_ASSO',
}

export interface INavigationButtons {
  cta?: EHeaderCta
}

export interface IMenuItem {
  title: string
  href?: string | null
  firstLevelItems?: IFirstLevelItem[]
}

interface IFirstLevelItem {
  title: string
  icon: string
  badge?: string
  href?: string | null
  secondLevelitems?: {
    title?: string
    items: ISecondLevelItem[]
  }
}

interface ISecondLevelItem {
  text: string
  href: string
  type?: string
}
