import { useEffect } from 'react'

// Utility to determine breakpoint
const getBreakpoint = (mobileBreakpoint: string) => {
  return window.matchMedia(`(min-width: ${mobileBreakpoint})`).matches ? 100 : 0
}

// Utility to toggle body overflow
const toggleBodyOverflow = (isOpen: boolean) => {
  document.body.style.overflowY = isOpen ? 'hidden' : 'scroll'
}

// Utility to handle scroll and navigation fix logic
const handleScroll = (
  navigationIsFixed: boolean,
  setNavigationIsFixed: (value: boolean) => void,
  breakpoint: number
) => {
  if (window?.scrollY > breakpoint) {
    if (!navigationIsFixed) {
      setNavigationIsFixed(true)
    }
  } else if (navigationIsFixed) {
    setNavigationIsFixed(false)
  }
}

// Main useEffect hook
const useFixedNavbar = (
  navigationIsFixed: boolean,
  setNavigationIsFixed: (value: boolean) => void,
  navigationMobileIsOpened: boolean,
  breakpoints: { mobile: string }
) => {
  useEffect(() => {
    const fixNavBar = () => {
      const breakpoint = getBreakpoint(breakpoints.mobile)

      handleScroll(navigationIsFixed, setNavigationIsFixed, breakpoint)
      toggleBodyOverflow(navigationMobileIsOpened)
    }

    let isMounted = true

    if (isMounted) {
      document.addEventListener('scroll', fixNavBar, { passive: true })
    }

    return () => {
      window?.removeEventListener('scroll', fixNavBar)
      isMounted = false
    }
  }, [navigationIsFixed, navigationMobileIsOpened, breakpoints])
}

export default useFixedNavbar
