import { IMenuItem } from 'src/components/Menu/type'

export const besoins: IMenuItem = {
  title: 'Votre asso',
  firstLevelItems: [
    {
      icon: 'common/icon/multicolor/menu/Asso-par-secteur_icone-menu',
      title: 'Par secteur',
      secondLevelitems: {
        items: [
          {
            text: 'Sport',
            href: '/associations/logiciel-club-de-sport/',
          },
          {
            text: 'Loisirs',
            href: '/associations/logiciel-association-loisirs/',
          },
          {
            text: 'Réseaux',
            href: '/reseaux/',
          },
          {
            text: 'Autres secteurs',
            href: '/associations/',
            type: 'extra',
          },
        ],
      },
    },
    {
      icon: 'common/icon/multicolor/menu/Asso-par-membres_icone-menu',
      title: 'Par nombre de membres',
      secondLevelitems: {
        items: [
          {
            text: 'Moins de 1 000',
            href: '/taille/moins-de-1000-membres/',
          },
          {
            text: 'Entre 1 000 et 10 000',
            href: '/taille/1000-a-10000-membres/',
          },
          {
            text: 'Plus de 10 000',
            href: '/taille/plus-de-10000-membres/',
          },
        ],
      },
    },
    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Asso-par-usage_icone-menu',
      title: 'Par usage',
      secondLevelitems: {
        items: [
          {
            text: 'Paiements et comptabilité',
            href: '/usages/formulaires-comptabilite/',
          },
          {
            text: 'Transactions et membres',
            href: '/usages/crm-formulaires/',
          },
          {
            text: 'CRM et envoi d’emails',
            href: '/usages/crm-emailing',
          },
        ],
      },
    },
  ],
}
