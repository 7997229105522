import { IMenuItem } from 'src/components/Menu/type'

export const solutions: IMenuItem = {
  title: 'Nos solutions',
  firstLevelItems: [
    {
      icon: 'common/icon/multicolor/menu/Solutions-compta_icone-menu',
      title: 'Comptabilité',
      href: '/comptabilite/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Plan personnalisé',
            href: '/comptabilite/#plan',
          },
          {
            text: 'Saisie des écritures',
            href: '/comptabilite/#saisies',
          },
          {
            text: 'Documents comptables',
            href: '/fonctionnalites/logiciel-comptabilite-association/documents-comptables/',
          },
          {
            text: 'Rapprochement bancaire',
            href: '/fonctionnalites/logiciel-comptabilite-association/excel/#rapprochement',
          },
          {
            text: 'Clôture comptable',
            href: '/fonctionnalites/logiciel-comptabilite-association/#cloture',
          },
          {
            text: 'Contributions volontaires',
            href: '/fonctionnalites/logiciel-comptabilite-association/#contributions',
          },
          {
            text: 'Comptabilité analytique',
            href: '/fonctionnalites/logiciel-comptabilite-association/#cloture',
          },
          {
            text: 'Budget prévisionnel',
            href: '/fonctionnalites/logiciel-comptabilite-association/budget-previsionnel/',
          },
        ],
      },
    },
    {
      icon: 'common/icon/multicolor/menu/Solutions-formulaires_icone-menu',
      title: 'Formulaires',
      href: '/formulaires/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Adhésions',
            href: '/fonctionnalites/adhesions/',
          },
          {
            text: 'Dons',
            href: '/fonctionnalites/logiciel-dons/',
          },
          {
            text: 'Billetteries',
            href: '/fonctionnalites/billetterie-association/',
          },
          {
            text: 'Boutique en ligne',
            href: '/fonctionnalites/boutique-en-ligne-association/',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-base-membres_icone-menu',
      title: 'Base de membres (CRM)',
      href: '/crm/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Fiches contact',
            href: '/fonctionnalites/gestion-membres-association/#membres',
          },
          {
            text: 'Segmentation de la base',
            href: '/fonctionnalites/gestion-membres-association/#groupes',
          },
          {
            text: 'Groupes dynamiques',
            href: '/crm/#dynamiques',
          },
          {
            text: 'Personnes morales',
            href: '/fonctionnalites/gestion-membres-association/#crm-structures',
          },
          {
            text: 'Annuaire',
            href: '/crm/#annuaire',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-emailing_icone-menu',
      title: "Envoi d'emails",
      href: '/emailing/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: "Modèles d'emails",
            href: '/emailing/#modeles',
          },
          {
            text: 'Liste de contacts',
            href: '/emailing/#listes',
          },
          {
            text: 'Suivi des statistiques',
            href: '/emailing/#statistiques',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-site-internet_icone-menu',
      title: 'Site internet',
      href: '/site-internet/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Modèles de pages',
            href: '/site-internet/#modeles',
          },
          {
            text: 'Nom de domaine',
            href: '/site-internet/#domaine',
          },
          {
            text: 'Espace réservé aux membres',
            href: '/site-internet/#intranet',
          },
          {
            text: 'Blog',
            href: '/fonctionnalites/site-association/#blog',
          },
          {
            text: 'Galerie photo',
            href: '/site-internet/#photos',
          },
          {
            text: 'Sondages',
            href: '/fonctionnalites/communication-association/#sondages',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-paiements_icone-menu',
      title: 'Paiements',
      href: '/paiements/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Liens de paiement',
            href: '/fonctionnalites/lien-de-paiement/',
          },
          {
            text: 'Paiement en plusieurs fois',
            href: '/paiements/#plusieurs',
          },
          {
            text: 'Paiements récurrents',
            href: '/paiements/#recurrents',
          },
          {
            text: 'Moyens de paiement',
            href: '/paiements/#moyens',
          },
          {
            text: 'Pourboire',
            href: '/fonctionnalites/solution-paiement-en-ligne-association/#pourboire',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-babyasso_icone-menu',
      title: "Création d'association",
      href: '/creer-association/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Rédaction des statuts',
            href: '/creer-association/#statuts',
          },
          {
            text: 'Déclaration en préfecture',
            href: '/creer-association/#prefecture',
          },
          {
            text: 'Génération des documents',
            href: '/creer-association/#documents',
          },
          {
            text: 'Numéro SIRET',
            href: '/creer-association/#siren',
          },
          {
            text: 'Kit de lancement',
            href: '/creer-association/#assurance',
          },
        ],
      },
    },

    // SEPARATOR
    {
      icon: 'common/icon/multicolor/menu/Solutions-compte-pro_icone-menu',
      title: 'Compte Pro',
      badge: 'Nouveau',
      href: '/compte-pro/',
      secondLevelitems: {
        title: 'Fonctionnalités',
        items: [
          {
            text: 'Espace 100% en ligne',
            href: '/compte-pro/#espace',
          },
          {
            text: 'IBAN français',
            href: '/compte-pro/#iban',
          },
          {
            text: 'Cartes physiques et virtuelles',
            href: '/compte-pro/#cartes',
          },
          {
            text: 'Virements et prélèvements SEPA',
            href: '/compte-pro/#virements',
          },
          {
            text: 'Collectes et achats centralisés',
            href: '/compte-pro/#collectes',
          },
        ],
      },
    },
  ],
}
