import { formatMessage } from 'src/utils/translations'
import { EHeaderCta } from './type'
import { getTranslations } from 'src/utils/context'

export const getHeaderLogin = () => {
  const translations = getTranslations()

  const loginHref = process.env.GATSBY_SITE_LOGIN_URL

  return {
    title: translations['navigation_login'],
    href: loginHref,
  }
}

export const getHeaderCta = (cta: EHeaderCta) => {
  const translations = getTranslations()

  const subscriptionHref =
    process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
      ? 'https://app.assoconnect.com/sign-up/first?subscription=SERENITE'
      : 'https://app.springly.org/sign-up/first?subscription=SERENITY_US'

  const contactHref =
    process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
      ? 'https://tally.so/r/wMNDDp'
      : null

  const proAccountHref =
    process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
      ? 'https://tally.so/r/mZRkle'
      : null

  const babyAssoHref =
    process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
      ? 'https://tally.so/r/wMNDDp'
      : null

  switch (cta) {
    case EHeaderCta.APP_SUSCRIPTION:
      return {
        title: translations['inputbutton_text'],
        href: subscriptionHref,
      }
    case EHeaderCta.CONTACT:
      return (
        contactHref && {
          title: translations['contact_cta'],
          href: contactHref,
        }
      )
    case EHeaderCta.PRO_ACCOUNT:
      return (
        proAccountHref && {
          title: translations['pro_account_cta'],
          href: proAccountHref,
        }
      )
    case EHeaderCta.BABY_ASSO:
      return (
        babyAssoHref && {
          title: translations['baby_asso_cta'],
          href: babyAssoHref,
        }
      )

    default:
      return {
        title: translations['inputbutton_text'],
        href: subscriptionHref,
      }
  }
}

export const getNavigationLogoSrc = (navigationIsFixed: boolean) => {
  const translations = getTranslations()

  return navigationIsFixed
    ? `common/logo/${formatMessage('site_name', translations)}-simple`
    : `common/logo/${formatMessage('site_name', translations)}`
}
